<template>
  <div class="conversation-messages-wrapper"
       :class="{'dragging-file': isDraggingFile, 'needs-to-quote': showingNeedsToQuoteBanner}" @drop.prevent="addFile"
       @dragover.prevent="isDraggingFile = true" @dragleave.prevent="isDraggingFile = false">
    <div class="conversation-messages" ref="conversationMessages">

      <VueEternalLoading v-model:is-initial="isInitial"
                         :class="{'h-100': messages.length === 0}"
                         :load="loadMessages"
                         position="top">
        <template #no-more>
          <!-- No messages -->
          <div v-if="messages.length === 0" class="send-first-message h-100">
            <div class="icon">
              <i class="fi fi-mail"></i>
            </div>
            <div>
              Send your first message
            </div>
          </div>
          <!-- / No messages -->
          <div class="this-stops-default-no-more-message-showing"></div>
        </template>
      </VueEternalLoading>

      <read-indicator :conversation="conversation"
                      :conversation-members="conversationMembers"
                      :message="null" :newer-message="firstMessage"
      ></read-indicator>
      <div v-for="(message, i) in messagesToShow">
        <message :conversation-members="conversationMembers"
                 @navigate-to-quotes="() => $emit('navigate-to-quotes')"
                 @navigate-to-job-details="() => $emit('navigate-to-job-details')"
                 :job-id="jobId" :message="message" :key="i"></message>
        <read-indicator :conversation="conversation"
                        :conversation-members="conversationMembers"
                        :message="message" :newer-message="getNewerMessage(i)"></read-indicator>
      </div>

      <!--      <message-->
      <!--          :message="{created_at: '2021-12-02', message: 'Hi - I want to introduce Harvey our newest Avenger'}"></message>-->
      <!--      <message class="sent-by-me"-->
      <!--               :message="{created_at: '2021-12-03', message: `Hi Harvey - you're the best Avenger yet!`}"></message>-->
      <!--      <message class="sent-by-me"-->
      <!--               :message="{created_at: '2021-12-03', message: `Remember, sleeping is cheating`}"></message>-->

    </div>
    <!-- Send Message -->
    <div v-if="!conversation.closed" class="send-message-wrapper p-3">
      <div class="send-message-flex-wrapper">
        <div class="status-update" v-if="shouldShowStatusUpdateButton">
          <update-job-status-button-and-modal :job="job"></update-job-status-button-and-modal>
        </div>
        <div class="file-upload">
          <input multiple
                 :key="fileUploadInputKey"
                 @change="handleFilesUploaded"
                 ref="file" type="file" class="file-uploader">
          <button @click.prevent="uploadFile"
                  class="btn btn-icon btn-outline-primary me-2" type="button">
            <i class="fi-upload-file"></i>
          </button>
        </div>
        <div class="left">
          <input placeholder="Enter message here..."
                 :disabled="isSendingMessage"
                 @keydown.enter="sendMessage"
                 type="text" class="form-control" v-model="messageToSend"/>
        </div>
        <div class="right">
          <button
              :class="{'opacity-50': (isSendingMessage||!canSendMessage)}"
              type="button"
              @click.prevent="sendMessage"
              class="btn btn-primary">
            <spinner v-if="isSendingMessage" class="spinner-border-sm me-2"></spinner>
            {{ isSendingMessage ? 'Sending' : 'Send' }}
          </button>
        </div>
      </div>

      <!-- Files -->
      <div class="files mt-3" v-if="filesToSend.length">
        <div class="badge bg-dark file py-2 px-3" v-for="file in filesToSend">
          {{ file.name }}
          <button @click.prevent="removeFile(file)"
                  type="button" class="btn btn-xxs btn-link text-white btn-icon w-auto py-1 ms-2">
            <i class="fi-x"></i>
          </button>
        </div>
      </div>
      <!-- / Files -->
    </div>
    <!-- / Send Message -->

    <!-- Conversation Closed -->
    <div v-if="conversation.closed" class="p-3 bg-syndesi-gray conversation-closed">
      This conversation has been closed
    </div>
    <!-- / Conversation Closed -->
  </div>
</template>

<script>
import Message from "../../Jobs/Messaging/JobMessaging/ConversationMessages/Message";
import {mapGetters} from "vuex";
import conversations from "../../../api/conversations";
import {useToast} from 'vue-toastification';
import Spinner from "../../../components/Spinner";
import ReadIndicator from "../../Jobs/Messaging/JobMessaging/ConversationMessages/ReadIndicator";
import {VueEternalLoading} from '@ts-pro/vue-eternal-loading';
import eventBus from "../../../eventBus";
import UpdateJobStatusButtonAndModal from "./ConversationMessages/UpdateJobStatusButtonAndModal";

const toast = useToast();
const PAGE_SIZE = 1000;

export default {
  name: "ConversationMessages",
  props: ['job', 'conversation', 'showingNeedsToQuoteBanner'],
  components: {UpdateJobStatusButtonAndModal, Message, Spinner, ReadIndicator, VueEternalLoading},
  data() {
    return {
      page: 1,
      messages: [],

      messageToSend: '',
      filesToSend: [],
      fileUploadInputKey: 1,
      isSendingMessage: false,
      isInitial: false,

      isMarkingAsRead: false,

      conversationMembers: [],

      isDraggingFile: false
    }
  },
  created() {
    let vm = this;
    vm.conversationMembers = _.cloneDeep(this.conversation.conversation_members);
    window.Echo.private(`conversations.${this.conversation.id}.${this.userRole.id}`).listen('MessageSent', (e) => {
      vm.handleMessageReceived(e.message);
    });
    window.Echo.private(`conversations.${this.conversation.id}.${this.userRole.id}`).listen('UserReadConversation', (e) => {
      vm.handleUserReadConversation(e.conversationMember);
    });
    eventBus.$on('request-file-upload', () => {
      window.setTimeout(() => {
        vm.uploadFile();
      }, 500)
    });
  },
  watch: {
    conversation() {
      this.page = 1;
      this.messages = [];
      this.isInitial = true;
      let vm = this;
      vm.conversationMembers = _.cloneDeep(this.conversation.conversation_members);
      window.Echo.private(`conversations.${this.conversation.id}.${this.userRole.id}`).listen('MessageSent', (e) => {
        vm.handleMessageReceived(e.message);
      });
      window.Echo.private(`conversations.${this.conversation.id}.${this.userRole.id}`).listen('UserReadConversation', (e) => {
        vm.handleUserReadConversation(e.conversationMember);
      });
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole',
      isProvider: 'isProvider'
    }),

    shouldShowStatusUpdateButton() {
      // return (this.isProvider && this.job.status == 1); // is provider and job is active
      return this.isProvider; // is provider and job is active
    },

    jobId() {
      return this.job ? this.job.id : null;
    },

    firstMessage() {
      return _.first(this.messages);
    },

    messagesToShow() {
      return _.uniqBy(_.orderBy(this.messages, 'created_at'), 'id')
    },

    canSendMessage() {
      if (this.filesToSend.length) {
        return true;
      }
      return this.messageToSend
    }
  },
  methods: {
    getNewerMessage(laterMessageIndex) {
      if (this.messagesToShow.length === laterMessageIndex + 1) {
        return null;
      }
      return this.messagesToShow[Number(laterMessageIndex) + 1];
    },

    uploadFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },

    handleFilesUploaded(e) {
      let files = e.target.files;
      ([...files]).forEach(f => {
        this.filesToSend.push(f);
      });
    },

    addFile(e) {
      this.isDraggingFile = false;
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...droppedFiles]).forEach(f => {
        this.filesToSend.push(f);
      });
    },

    removeFile(file) {
      this.filesToSend = this.filesToSend.filter(f => {
        return f !== file;
      });
    },

    loadMessages({loaded, noMore}) {
      let vm = this;
      conversations.searchMessages(
          this.jobId,
          this.conversation.id,
          this.page,
          PAGE_SIZE
      ).then(({data}) => {
        vm.messages.unshift(...data.data.reverse());

        if (data.data.length == 0) {
          noMore()
        } else {
          loaded(this.messages.length, PAGE_SIZE);
        }

        if (this.page === 1) {
          vm.scrollMessagesToBottom();
          vm.markAsRead();
        }
        vm.page += 1;
      }).catch(e => {
        console.log(e);
        // toast.error('Error loading messages');
      })
    },

    sendMessage() {
      if (this.isSendingMessage || !this.canSendMessage) {
        return false;
      }
      let vm = this;
      vm.isSendingMessage = true;

      let messageToSend = this.messageToSend;
      if (this.filesToSend.length && messageToSend.trim().length == 0) {
        messageToSend = 'Please see attached file'
      }

      // Put in pending messages
      vm.messages.push({
        conversation_id: this.conversation.id,
        message: messageToSend,
        sent_by_user_role_id: vm.userRole.id,
        sent_from_syndesi: false,
        created_at: window.moment(),
        updated_at: window.moment(),
        pending: true,
        id: window.moment().unix()
      });
      vm.scrollMessagesToBottom();

      conversations.sendMessage(
          this.jobId,
          this.conversation.id,
          vm.messageToSend,
          vm.filesToSend
      ).then(() => {
        // vm.isSendingMessage = false;
        // vm.messageToSend = '';
        // vm.fileUploadInputKey += 1;
        // vm.filesToSend = [];
      }).catch(e => {
        console.log(e);
        // vm.isSendingMessage = false;
        toast.error('Error sending message');
      });
      vm.isSendingMessage = false;
      vm.messageToSend = '';
      vm.fileUploadInputKey += 1;
      vm.filesToSend = [];
    },

    markAsRead() {
      this.isMarkingAsRead = true;
      let vm = this;
      conversations.markAsRead(
          this.jobId,
          this.conversation.id
      ).then(r => {
        vm.isMarkingAsRead = false;
      }).catch(e => {
        console.log(e);
        vm.isMarkingAsRead = false;
        toast.error('Error marking as read');
      });
    },

    scrollMessagesToBottom() {
      let vm = this;
      window.setTimeout(function () {
        let elem = vm.$refs['conversationMessages'];
        elem.scrollTop = elem.scrollHeight;
      }, 100)
    },

    handleMessageReceived(message) {
      let vm = this;
      if (message.conversation_id === this.conversation.id) {
        let pendingMessage = _.find(this.messages, em => {
          return (em.sent_by_user_role_id == message.sent_by_user_role_id)
              && (em.conversation_id == message.conversation_id)
              && (em.message == message.message)
              && em.pending == true;
        });
        if (pendingMessage) {
          // pendingMessage = message;
          vm.messages = _.without(vm.messages, pendingMessage);
        }
        this.messages.push(message);

        this.scrollMessagesToBottom();

        if (message.sent_by_user_role_id !== this.userRole.id) {
          this.markAsRead();
        }
      }
    },

    handleUserReadConversation(conversationMember) {
      // Update selected conversation
      if (conversationMember.conversation_id === this.conversation.id) {
        this.conversationMembers = _.map(this.conversationMembers, member => {
          if (member.id === conversationMember.id) {
            return {
              ...member,
              ...conversationMember
            }
          }
          return member;
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.h-100 {
  height: 100%;
}

.send-first-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;

  .icon {
    margin-bottom: 10px;
    font-size: 35px;
  }
}

.conversation-messages-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  //height: 100%;
  //height: calc(100% - 62px);
  height: calc(100% - 62px - 55px);

  &.needs-to-quote {
    height: calc(100% - 62px - 55px - 55px) !important;
  }

  position: relative;

  &.dragging-file {
    background: #efecf3;

    &:before {
      content: ""; // :before and :after both require content
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; // Push the element 50% of the container's width to the right
      background-image: url('/img/file-upload-icon.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30%;
    }

    .conversation-messages {
      opacity: 0;
    }

    .send-message-wrapper {
      opacity: 0;
    }
  }
}

.conversation-messages {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 20px;

  // Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.send-message {
  flex-shrink: 1;
  //border-top: 1px solid #efecf3 !important;
  padding: 20px;

  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    padding-left: 10px;
  }
}

.files {
  .file {
    font-size: 14px;
    margin-right: 8px;
  }
}

.file-uploader {
  display: none;
}

.send-message-wrapper {
  .send-message-flex-wrapper {
    display: flex;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
      padding-left: 10px;
    }
  }
}

.conversation-closed {
  font-size: 14px;
  color: #1f1b2d;
  font-weight: 600;
}
</style>