<template>
  <div class="needs-to-quote-banner">
    <div>
      <a href="#" @click.prevent="$emit('navigate-to-quotes')"
         class="text-decoration-none">
        <i class="fi-briefcase opacity-80 me-2"></i>
        <!-- You've not quoted yet - click here to provide a quote! -->
         You have not provided a proposal yet - click here to provide a proposal!
      </a>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "NeedsToQuoteBanner",
  components: {},
  props: ['job', 'isMobile'],
  methods: {},
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole',
      // isClient: 'isClient',
      // isAdvisor: 'isAdvisor',
      // isProvider: 'isProvider'
    })
  }
}
</script>

<style scoped lang="scss">
.needs-to-quote-banner {
  flex-shrink: 1;
  //background: #fff;
  padding: 0 15px;
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  display: flex;
  //justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #efecf3 !important;
  font-size: 13.5px;

  color: #1f1b2d;
  font-weight: 500;

  background: rgba(255, 255, 255, 0.6);

  a {
    font-weight: bold;
  }

  span {
    font-weight: 600;
  }
}
</style>